<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon> Rel. ADITIVOS
  </v-btn>
</template>
<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_3.png"

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Orcamento",
  components: {
  },
  data: () => withCRUDUtils({
    prorrogacao: [],
    budget: [],
    taxa: [],
    equipamentos: [],
    matmed: [],
    loading: false,
    totalFee: null,
    totalEquipment: null
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProrrogacao(),
          this.getTaxa(),
          this.getEquipamentos(),
          this.getmatMed()
        ]);
        await this.generatePDF()
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-54):", error);
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.warning("Falha ao adicionar a imagem ao PDF:", error);
      }

      const data = this.budget[0];

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45
      let yPosition = 45

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Aditivo(s) do Orçamento nº ${data?.id}`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28); 
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}` : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (doc.internal.getNumberOfPages() === 1) {
        yPosition = 75
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Segue o(s) aditivo(s) detalhado(s) conforme solicitação.`, 8, yPosition);

        yPosition = 82
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Ficamos no aguardo da autorização para darmos início aos serviços abaixo.`, 8, yPosition);
      }
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      let xPosition = 10;
      let yPosition = 93;
      const pageWidth = 210;
      const maxHeight = 270;
      const lineHeight = 6;

      const data = this.budget[0];
      const prorrogacao = this.prorrogacao;

      const startDate = new Date(data?.data_inicio);
      const endDate = new Date(data?.data_fim);
      const numberOfDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      const operadora = data && data.convenio ? data.convenio : '';

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Resumo do Aditivo");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Resumo dos Aditivos", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      if (prorrogacao && prorrogacao.length > 0) {
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Procedimento(s)", xPosition, yPosition);
      yPosition += lineHeight;

      const table = prorrogacao.map(item => {
        // Calcula o valor unitário após aplicar desconto/acréscimo
        const discount = item.desconto ? parseFloat(item.desconto) / 100 : 0;
        const addition = item.acrescimo ? parseFloat(item.acrescimo) / 100 : 0;
        const adjustedUnitValue = (item.cobranca !== '0' && item.valor_cobranca)
          ? parseFloat(item.valor_cobranca) * (1 + addition - discount)
          : 0;

        const qtdRealizada = item.especial === true ? parseInt(item.qtdEspecial) : item.qtd;
        const valorTotal = adjustedUnitValue * qtdRealizada;
        const nomeProcedimento = (item?.nomecomercial  && item.nomecomercial.trim() !== "") ? `${item.procedimento} - (${item.nomecomercial})` : item.procedimento;

        return {
          'Adtivo': item.nr_aditivo,
          'Código': item.codigo,
          'Procedimento': nomeProcedimento,
          'Frequência': item.especial === true ? '' : item.frequencia,
          'Qtd.': qtdRealizada,
          'Valor Unitário': UtilsPDF.formatToBRL(parseFloat(item.valor_cobranca) || 0),
          'Des %.': item.cobranca === '0' ? '100.0' : (item.desconto || '--'),
          'Acres %.': (item.cobranca === '0' || item.desconto === '100') ? '--' : (item.acrescimo || '--'),
          'Valor Total': UtilsPDF.formatToBRL(valorTotal)
        };
      });

      const columns = ['Adtivo', 'Código', 'Procedimento', 'Frequência', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
      const rows = [];
      table.forEach(item => {
        const row = [];
        columns.forEach(column => {
          row.push(item[column]);
        });
        rows.push(row);
      });

      doc.autoTable({
        startY: yPosition,
        head: [columns],
        body: rows,
        styles: { lineWidth: 0.1 },
        margin: { left: 8, right: 8 },
        columnStyles: {
          0: { halign: 'center' },
          3: { cellWidth: 30 },
          4: { halign: 'center' },
          8: { cellWidth: 28 }
        },
      });

      yPosition = doc.lastAutoTable.finalY + lineHeight;

      // Cálculo do Valor Total dos Procedimentos
      const totalBudget = table.reduce((acc, item) => {
        const valorTotal = UtilsPDF.parseFormattedCurrency(item['Valor Total']) || 0;
        return acc + valorTotal;
      }, 0);
      this.totalBudget = totalBudget;
      yPosition += lineHeight;
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Valor Total do(s) Procedimento(s): ${UtilsPDF.formatToBRL2(totalBudget)}`, 200, yPosition, { align: "right" });
      yPosition += lineHeight * 2;
    }
      const taxas = this.taxa;
      if (taxas && taxas.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Taxa(s)", xPosition, yPosition);

        yPosition += lineHeight;
        this.totalFee = 0;

        const columns = [
          'Adtivo',
          'Código',
          'Taxa',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        taxas.forEach(taxa => {
          if (taxa.ativo && taxa.aditivo_taxa) {
            const valorCobrancaTaxa = taxa?.valor_cobranca_taxa || 0;
            const valorCobrancaFinalTaxa = taxa?.valor_cobranca_final_taxa || 0;
            const nomeTaxa = (taxa?.taxa?.nomecomercial  && taxa.taxa.nomecomercial.trim() !== "") ? `${taxa?.taxa?.taxa?.nome} - (${taxa?.taxa?.nomecomercial})` : taxa?.taxa?.taxa?.nome;

            const row = {
              'Adtivo': taxa.nr_aditivo,
              'Código': taxa?.taxa?.codigo || '',
              'Taxa': nomeTaxa || '',
              'Qtd.': String(taxa?.quantidade_taxa || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaTaxa),
              'Des %.': taxa?.desconto_taxa || '--',
              'Acres %.': taxa?.acrescimo_taxa || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalTaxa)
            };

            rows.push(Object.values(row));

            this.totalFee += valorCobrancaFinalTaxa;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 },
            columnStyles: {
              0: { halign: 'center' },
              9: { cellWidth: 28 }
            },
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          console.error(error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(this.totalFee)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);
      

      // MatMed
      const matmeds = this.matmed;
      // console.log(matmeds)
      if (matmeds && matmeds.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Mat./Med./Dietas(s)", xPosition, yPosition);
        yPosition += lineHeight;
        // let totalMatMed = 0;
        this.totalMatMed = 0

        const columns = [
          'Adtivo',
          'Tipo',
          'Código',
          'Produto',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];
        // Ordenando por tipo antes de iterar
        matmeds.sort((a, b) => a.matmed.tipo.tipo.localeCompare(b.matmed.tipo.tipo));
        matmeds.forEach(matmed => {
          if (matmed.ativo && matmed.aditivo_matmed) {
            const valorCobrancaMatmed = matmed?.valor_cobranca_matmed || 0;
            const valorCobrancaFinalmatmed = matmed?.valor_cobranca_final_matmed || 0;
            const nomeMatmed = matmed.matmed.nome
            
            const row = {
              'Adtivo': matmed.nr_aditivo,
              'Tipo': matmed.matmed.tipo.tipo || '',
              'Código': matmed.codigo || '',
              'Produto': nomeMatmed || '',
              'Qtd.': String(matmed?.quantidade_matmed || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaMatmed),
              'Des %.': matmed?.desconto_matmed || '--',
              'Acres %.': matmed?.acrescimo_matmed || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalmatmed)
            };

            rows.push(Object.values(row));

            this.totalMatMed = (this.totalMatMed || 0) + valorCobrancaFinalmatmed;
 
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 },
            columnStyles: {
              0: { halign: 'center' },
              8: { cellWidth: 28 }
            },
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-344):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Mat./Med./Dieta(s): ${UtilsPDF.formatToBRL2(this.totalMatMed)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);


      const equipamentos = this.equipamentos;
      this.totalEquipment = 0;
      if (equipamentos && equipamentos.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Equipamento(s)", xPosition, yPosition);

        yPosition += lineHeight;

        const columns = [
          'Adtivo',
          'Código',
          'Equipamento',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        equipamentos.forEach(equipamento => {
          if (equipamento.ativo && equipamento.aditivo_equipamento) {
            const valorCobranca = equipamento?.valor_cobranca_equipamento || 0;
            const valorCobrancaFinal = equipamento?.valor_cobranca_final_equipamento || 0;
            const nomeEquipamento = (equipamento?.equipamento?.nomecomercial &&  equipamento.equipamento.nomecomercial.trim() !== "") ? `${equipamento?.equipamento?.equipamento?.nome} - (${equipamento?.equipamento?.nomecomercial})` : equipamento?.equipamento?.equipamento?.nome;

            const row = {
              'Adtivo': equipamento.nr_aditivo,
              'Código': equipamento?.equipamento?.codigo || '',
              'Equipamento': nomeEquipamento || '',
              'Qtd.': String(equipamento?.quantidade_equipamento || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobranca),
              'Des %.': equipamento?.desconto_equipamento || '--',
              'Acres %.': equipamento?.acrescimo_equipamento || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinal)
            };

            rows.push(Object.values(row));

this.totalEquipment += valorCobrancaFinal;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 },
            columnStyles: {
              0: { halign: 'center' },
              7: { cellWidth: 28 }
            },
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          console.error(error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Equipamento(s): ${UtilsPDF.formatToBRL2(this.totalEquipment)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de equipamento encontrado');
      }

      const totalOrcamento = (this.totalBudget || 0) + (this.totalFee || 0) + (this.totalEquipment || 0) + (this.totalMatMed || 0);
      const dailyAverage = totalOrcamento / numberOfDays;


      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);

      const text = 'do Aditivo'
      UtilsPDF.summary(doc, yPosition, lineHeight, dailyAverage, totalOrcamento, text)

      yPosition += lineHeight;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);
      // doc.text(`Valor Total do Aditivo: ${totalBudget ? UtilsPDF.formatToBRL(totalBudget) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });

      yPosition += lineHeight + 2;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

      UtilsPDF.addSignatureBlock(doc, operadora, lineHeight, xPosition, 245);

      UtilsPDF.addPageNumbers(doc);
      const data_inicio = data && data?.data_inicio;
      const data_fim = data && data?.data_fim;

      const aditivoNr = data && data.id ? data.id : '';
      const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';
      const nomePaciente = data && data.paciente ? data.paciente : '';

      const filename = `Aditivos_do_Orçamento_Nº_${aditivoNr}_${nomePaciente}_${operadora}_Período_${period}.pdf`;

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);

      try {
        const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)
        const signedPdfUrl = signedForm.path
        this.download(signedPdfUrl, filename)
        this.$toast.success('O download do relatório foi realizado com sucesso!');
      } catch (error) {
        console.error("Error signing PDF:", error.response ? error.response.data : error.message);
        this.$toast.error('Ocorreu um erro ao tentar gerar o PDF (L-452).');
      }
    },
    async getProrrogacao() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-prorrogacao-resumo/?orcamento=${this.selectedBudgets[0]}`);
          this.prorrogacao = data.filter(item => item.aditivo === true);
          // console.log('Prorrogacao --> ', this.prorrogacao)
        } catch (error) {
          this.$toast.warning('Erro ao buscar os dados da Prorrogação (L-462):', error);
        }
      }
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.budget = data;
        } catch (error) {
          this.$toast.warning('Erro ao buscar os dados do Orçamento (L-472):', error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.selectedBudgets[0]}`);
          this.taxa =  data.filter(item => item.ativo && item.aditivo_taxa);
          // console.log('Taxas --> ', this.taxa)
        } catch (error) {
          console.error('Erro ao buscar os dados da taxa (L-483):', error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedBudgets[0]}`);
          this.equipamentos = data.filter(item => item.ativo && item.aditivo_equipamento);
          // console.log('Equipamentos --> ', this.equipamentos)
        } catch (error) {
          console.error('Erro ao buscar os dados do Equipamento (L-494):', error);
        }
      }
    },
    async getmatMed() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/matmeds/?orcamento=${this.selectedBudgets[0]}`);
          this.matmed = data.filter(item => item.ativo && item.aditivo_matmed);
          // console.log('Equipamentos --> ', this.equipamentos)
        } catch (error) {
          console.error('Erro ao buscar os dados do Equipamento (L-494):', error);
        }
      }
    }
  },
};
</script>